<template>
  <div class="contact-form">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="col-12">
                <div class="title-sm title px-0 col-lg-3 col-4" :class="border+'-title'">
                    <h6 class="title-sm py-1 mx-4">{{$t('contactForm.contactForm')}}</h6>
                </div>
                <div class="pt-3"></div>
                <div class="mb-1 bb" :class="border">
                  <text-input :label="$t('contactForm.name')" class="w-100" type="text" id="name" v-model="form.name"></text-input>
                </div>
                <div class="mb-1 bb" :class="border">
                  <text-input :label="$t('contactForm.phone')" type="text" id="tnumber"  v-model="form.tnumber"></text-input>
                </div>
                <div class="mb-1 bb" :class="border">
                  <text-input :label="$t('contactForm.email')" type="email" id="email"  v-model="form.email"></text-input>
                </div>
                <div class="mb-1 bb" :class="border">
                  <text-input :label="$t('contactForm.subject')" type="text" id="subject"  v-model="form.subject"></text-input>
                </div>
                <div class="mb-1 bb" :class="border">
                  <text-area-input :label="$t('contactForm.txt')" id="txt" rows="8"  v-model="form.txt"></text-area-input>
                </div>
                <transition name="slide-fade" mode="in-out">
                <b-alert variant="danger" show v-if="errors">
                  <span>خطا !</span>

                    <p v-for="(error,field) in errors" :key="field">{{ error[0]}}</p>

                </b-alert>
                </transition>
                <transition name="slide-fade" mode="in-out">
                <b-alert variant="success" show v-if="success">
                  <p>{{ success }}</p>
                </b-alert>
              </transition>
                <button type="button"  name="submit" class="submit mt-3" @click="sendData()">{{ $t('contactForm.submit') }}</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from './inputs/TextInput';
import TextAreaInput from './inputs/TextAreaInput';
import httpClient from '@/services/http.service';
export default {
  name:'ContactForm',
  props: {
    border:String,
  },
  components: {
    TextInput,
    TextAreaInput,
  },
  data(){
    return {
      form:{
        name:'',
        tnumber:'',
        email:'',
        subject:'',
        txt:''
      },
      success:false,
      errors: null,
    };

  },
  methods: {
    async sendData() {
      try {
        const {status , data } = await httpClient.post('/fa/contacts',this.form);
        if (status == 200) {
          this.success = data;
          this.errors = null;
        }
      } catch (e) {
        this.errors = e.response.data.errors;
      }
    }
  }
}
</script>

<style lang="css" scoped>
h6{
  font-size: 18px;
  font-weight: bolder;
  color: #fff;
}
.submit{
  background: transparent;
  border-radius: 25px;
  padding-right: 20px;
  padding-left: 20px;
  color: #fff;
  direction: ltr;
  float: left;
  font-size: 20px;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(.04,.69,.77,.05);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transition: all .8s cubic-bezier(.04,.69,.77,.05);
  transform: translateY(40px);
  opacity: 0;
}
.bb{
  border-top: none;
  border-right: none;
  border-left: none;
}
</style>
